import React from 'react';
import styled from 'styled-components';
import {colors, media} from '../modules/styles';
import {FormGroup, Position} from '@blueprintjs/core';
import {useStore} from 'react-hookstore';
import JurisdictionPicker from './JurisdictionPicker';
import AddToReportButton from './AddToReportButton';
import {
  filterJurisdictionsBySubscription,
  getNameByCode,
  jurisdictionCodes,
} from '../modules/jurisdictions';
import SuggestMultiSelect from './SuggestMultiSelect';

export default function PoliciesFilters({
  currentUser,
  areas,
  selectedAreas,
  selectedJurisdictions,
  setSelectedAreas,
  setSelectedJurisdictions,
  onCreateReport,
  onAppendToReport,
  results,
}) {
  const [isMobile] = useStore('isMobile');

  const areaOptions = areas.filter(
    area => results.findIndex(policy => policy.area.id === area.id) > -1
  );

  const filteredJurisdictions = filterJurisdictionsBySubscription(
    currentUser,
    jurisdictionCodes
  );
  const jurisdictionOptions = filteredJurisdictions
    .filter(
      jurisdiction =>
        results.findIndex(policy => policy.jurisdiction === jurisdiction) > -1
    )
    .map(jurisdiction => ({
      id: jurisdiction,
      name: getNameByCode(jurisdiction),
    }));

  return (
    <Comp>
      <>
        <Jurisdictions>
          <FormGroup
            className="group"
            label="Area of Law:"
            labelFor="area-of-law"
          >
            <SuggestMultiSelect
              id="area-of-law"
              items={areaOptions}
              selectedItems={selectedAreas.map(areaId => ({
                id: areaId,
                name: areas.find(u => u.id === areaId)?.name,
              }))}
              onSelect={area => {
                if (!selectedAreas.includes(area.id)) {
                  setSelectedAreas(prevSelectedAreas => [
                    ...prevSelectedAreas,
                    area.id,
                  ]);
                } else {
                  setSelectedAreas(prevSelectedAreas => {
                    const newList = [...prevSelectedAreas];
                    newList.splice(
                      prevSelectedAreas.findIndex(areaId => areaId === area.id),
                      1
                    );
                    return newList;
                  });
                }
              }}
              onRemove={(_, index) => {
                setSelectedAreas(prevSelectedAreas => {
                  const newList = [...prevSelectedAreas];
                  newList.splice(index, 1);
                  return newList;
                });
              }}
              position={isMobile ? Position.BOTTOM : Position.RIGHT}
              enableNew={false}
              placeholder="Showing all Areas"
              hideSelectedItems={false}
              tagInputProps={{
                tagProps: {minimal: true, intent: 'none'},
                fill: true,
              }}
              fill={true}
            />
            {selectedAreas.length > 0 && (
              <Reset onClick={() => setSelectedAreas([])}>
                Clear Area Filter
              </Reset>
            )}
          </FormGroup>
        </Jurisdictions>
      </>

      {filteredJurisdictions.length > 1 && (
        <Jurisdictions>
          <FormGroup
            className="group"
            label="Jurisdiction:"
            labelFor="jurisdiction"
          >
            <JurisdictionPicker
              id="jurisdiction"
              items={jurisdictionOptions}
              onSelect={jurisdiction => {
                if (!selectedJurisdictions.includes(jurisdiction.id)) {
                  setSelectedJurisdictions(prevSelectedJurisdictions => [
                    ...prevSelectedJurisdictions,
                    jurisdiction.id,
                  ]);
                } else {
                  setSelectedJurisdictions(prevSelectedJurisdictions => {
                    const newList = [...prevSelectedJurisdictions];
                    newList.splice(
                      prevSelectedJurisdictions.findIndex(
                        jurisdictionId => jurisdictionId === jurisdiction.id
                      ),
                      1
                    );
                    return newList;
                  });
                }
              }}
              onRemove={(_, index) => {
                setSelectedJurisdictions(prevSelectedJurisdictions => {
                  const newList = [...prevSelectedJurisdictions];
                  newList.splice(index, 1);
                  return newList;
                });
              }}
              selectedItems={selectedJurisdictions.map(jur => ({
                id: jur,
                name: getNameByCode(jur),
              }))}
            />
            {selectedJurisdictions.length > 0 && (
              <Reset onClick={() => setSelectedJurisdictions([])}>
                Clear Jurisdiction Filter
              </Reset>
            )}
          </FormGroup>
        </Jurisdictions>
      )}

      {results.length > 0 && (
        <>
          <br />
          <br />
          <AddToReportButton
            onCreate={onCreateReport}
            onAppend={onAppendToReport}
          />
        </>
      )}
    </Comp>
  );
}

const Comp = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 450px;

  @media (min-width: ${media.lg}) {
    position: fixed;
    top: 150px;
    left: 50px;
    width: 220px;
  }

  .bp3-tab-indicator {
    background-color: ${colors.primary};
    height: 2px;
  }

  .bp3-tab {
    outline: none;
    color: ${colors.grayDark};

    &[aria-selected='true'],
    &:hover {
      color: ${colors.primary};
      .bp3-icon {
        color: ${colors.primary};
      }
    }

    & .bp3-icon {
      margin-right: 5px;
      color: ${colors.grayDark};
    }
  }

  hr {
    width: 100%;
    border: none;
    border-top: 1px solid ${colors.gray2};
  }
`;

const Jurisdictions = styled.div`
  width: 100%;

  .bp3-popover-target {
    width: 95%;
  }
`;

const Reset = styled.button`
  background: none;
  border: none;
  text-align: left;
  padding: 0;
  color: ${colors.primary};
  cursor: pointer;
  font-size: 0.8rem;

  &:hover {
    text-decoration: underline;
  }
`;
