import React, {useState} from 'react';
import styled from 'styled-components';
import {useStore} from 'react-hookstore';
import {colors, media} from '../modules/styles';
import ENV from '../environment';
import {emit} from '../modules/eventManager';
import NavItem from './NavItem';
import logo from '../assets/logo-white.svg';
import {Icon, Position, Menu, MenuItem, MenuDivider} from '@blueprintjs/core';
import Notifications from './Notifications';
import {useHistory} from 'react-router-dom';
import useLocalStorage from '../hooks/useLocalStorage';
import DesktopOnly from './DesktopOnly';
import MobileOnly from './MobileOnly';
import {Popover2} from '@blueprintjs/popover2';
import {SearchBox} from 'react-instantsearch-dom';
import HelpMenu from './HelpMenu';
import VideoModal from './VideoModal';

export default function Header() {
  const [isMobile] = useStore('isMobile');
  const [currentUser] = useStore('currentUser');
  const history = useHistory();
  const [, setUpdatesTourSeen] = useLocalStorage('updatesTourSeen', false);
  const [, setReportBuilderTourSeen] = useLocalStorage(
    'reportBuilderTourSeen',
    false
  );
  const [, setReportsTourSeen] = useLocalStorage('reportsTourSeen', false);
  const [, setPreferencesTourSeen] = useLocalStorage(
    'preferencesTourSeen',
    false
  );
  const [, setChecklistsTourPart1Seen] = useLocalStorage(
    'checklistsTourPart1Seen',
    false
  );
  const [, setChecklistsTourPart2Seen] = useLocalStorage(
    'checklistsTourPart2Seen',
    false
  );
  const [, setMyNotesTourSeen] = useLocalStorage('myNotesTourSeen', false);
  const [currentVideo, setCurrentVideo] = useState();
  // const [searchText, setSearchText] = useState('');

  const logOut = e => {
    e.preventDefault();
    emit('user:loggedOut');
  };

  const startTour = () => {
    // Reset all flags so the tours show again
    setUpdatesTourSeen(false);
    setReportBuilderTourSeen(false);
    setReportsTourSeen(false);
    setPreferencesTourSeen(false);
    setChecklistsTourPart1Seen(false);
    setChecklistsTourPart2Seen(false);
    setMyNotesTourSeen(false);
    emit('tour:start');
    history.push(`/m/updates`);
  };

  return (
    <Comp>
      <LogoLink href={ENV.HOME_PAGE}>
        <Logo src={logo} alt="Compliance Works Logo" />
      </LogoLink>

      <Nav>
        <Left>
          <NavItem
            to="/m/updates"
            theme={colors.primary}
            dataTour="updates-nav-button"
          >
            Latest Updates
          </NavItem>
          <NavItem
            to="/m/builder"
            theme={colors.primary}
            dataTour="builder-nav-button"
          >
            Info Hub
          </NavItem>
          <NavItem
            to="/m/policies"
            theme={colors.primary}
            dataTour="policies-nav-button"
          >
            Policies
          </NavItem>
          {/* {currentUser.isStaff && (
            <NavItem
              to="/m/tracker"
              theme={colors.primary}
              dataTour="reports-nav-button"
            >
              Tracker
            </NavItem>
          )} */}
          <NavItem
            to="/m/checklists"
            theme={colors.primary}
            dataTour="checklists-nav-button"
          >
            Checklists
          </NavItem>
          <NavItem
            to="/m/my"
            theme={colors.primary}
            dataTour="reports-nav-button"
          >
            My Info
          </NavItem>
        </Left>

        <Right>
          <div data-tour="search">
            <SearchBox
              autoFocus={true}
              translations={{
                placeholder: 'Search...',
              }}
              onChange={() => {
                if (document.location.pathname !== '/m/newSearch') {
                  history.push('/m/newSearch');
                }
              }}
            />
          </div>

          <Notifications />

          <DesktopOnly>
            <HeaderHelpMenu setCurrentVideo={setCurrentVideo}>
              <HelpIcon>
                <Icon icon="help" iconSize={16} />
              </HelpIcon>
            </HeaderHelpMenu>
          </DesktopOnly>

          {currentUser.isStaff && (
            <DesktopOnly>
              <NavItem to={ENV.ADMIN_HOME_PAGE} icon="settings"></NavItem>
            </DesktopOnly>
          )}

          <Popover2
            position={Position.BOTTOM_RIGHT}
            content={
              <Menu>
                <MenuDivider title="Reports" className="mobile-only" />
                <MenuItem
                  className="mobile-only"
                  icon="inbox-update"
                  text="Latest Updates"
                  onClick={() => history.push('/m/updates')}
                />
                <MenuItem
                  className="mobile-only"
                  icon="search-template"
                  text="Info Hub"
                  onClick={() => history.push('/m/builder')}
                />
                <MenuItem
                  className="mobile-only"
                  icon="align-left"
                  text="Policies"
                  onClick={() => history.push('/m/policies')}
                />
                <MenuItem
                  className="mobile-only"
                  icon="form"
                  text="Checklists"
                  onClick={() => history.push('/m/checklists')}
                />
                <MenuItem
                  className="mobile-only"
                  icon="take-action"
                  text="My Info"
                  onClick={() => history.push('/m/my')}
                />
                {currentUser.isStaff && (
                  <MenuItem
                    className="mobile-only"
                    icon="settings"
                    text="Admin"
                    onClick={() => history.push(ENV.ADMIN_HOME_PAGE)}
                  />
                )}

                <MenuDivider title="Settings" />
                <MenuItem
                  icon="settings"
                  text="Preferences"
                  onClick={() => history.push('/m/preferences')}
                />

                {!isMobile &&
                  (currentUser.role === 'ACCOUNT_OWNER' ||
                    currentUser.role === 'ADMIN') && (
                    <MenuItem
                      icon="cog"
                      text="Account Settings"
                      onClick={() => history.push('/m/settings')}
                    />
                  )}

                {!isMobile &&
                  currentUser.role !== 'ACCOUNT_OWNER' &&
                  currentUser.role !== 'ADMIN' && (
                    <MenuItem
                      icon="lock"
                      text="Change Password"
                      onClick={() => history.push('/forgotPassword')}
                    />
                  )}

                <MenuDivider title="Support" />
                <MenuItem
                  icon="presentation"
                  text="Restart Feature Tours"
                  onClick={startTour}
                />
                <HelpMenu
                  mobile={true}
                  setCurrentVideo={setCurrentVideo}
                  className="mobile-only"
                >
                  <MenuItem
                    icon="help"
                    text="Help / FAQ"
                    shouldDismissPopover={false}
                  />
                </HelpMenu>

                <MenuDivider title="Legal" />
                <MenuItem
                  icon="shield"
                  text="Privacy Policy"
                  onClick={() => history.push('/m/privacy')}
                />
                <MenuItem
                  icon="document"
                  text="Terms of Use"
                  onClick={() => history.push('/m/terms')}
                />
                <MenuItem
                  icon="endorsed"
                  text="Scope of Product"
                  onClick={() => history.push('/m/scopeOfProduct')}
                />
                <MenuDivider />
                <MenuItem icon="log-out" text="Log Out" onClick={logOut} />
              </Menu>
            }
          >
            <>
              <DesktopOnly>
                <Greeting data-tour="help-menu">
                  {currentUser.firstName}{' '}
                  <Icon icon="chevron-down" className="chevron" />
                </Greeting>
              </DesktopOnly>
              <MobileOnly>
                <MenuIcon>
                  <Icon icon="menu" iconSize={20} />
                </MenuIcon>
              </MobileOnly>
            </>
          </Popover2>
        </Right>
      </Nav>
      {currentVideo && (
        <VideoModal
          title={currentVideo.title}
          url={currentVideo.url}
          isOpen={!!currentVideo}
          onClose={() => setCurrentVideo()}
          canOutsideClickClose={false}
          usePortal={true}
        />
      )}
    </Comp>
  );
}

const Comp = styled.header`
  display: flex;
  align-items: center;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  padding: 0 15px;
  // border-bottom: 1px solid ${colors.gray2};
  background-color: ${colors.primary};

  @media (min-width: ${media.lg}) {
    padding: 0 50px;
  }

  .ais-SearchBox-form {
    background-color: none;
    height: 2.2rem;
    border-radius: 20px;
  }

  .ais-SearchBox-input {
    box-shadow: none;
    border-radius: 20px;
    border: none;
  }
`;

const LogoLink = styled.a`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  width: 130px;

  @media (min-width: ${media.sm}) {
    width: 180px;
  }
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  @media (min-width: ${media.lg}) {
    justify-content: space-between;
  }
`;

const Left = styled.div`
  display: none;

  @media (min-width: ${media.lg}) {
    display: flex;
    align-items: center;
    margin-left: 70px;
  }
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  max-width: 90%;
  margin-left: 30px;

  @media (min-width: 1200px) {
    margin-left: 80px;
    max-width: 600px;
  }

  .ais-SearchBox {
    width: 100%;
    max-width: 300px;
  }
`;

const Greeting = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: 1.1rem;
  padding: 6px 0;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  margin-left: 30px;
  color: white;

  &:hover {
    border-bottom: 2px solid ${colors.secondaryBright};
    color: ${colors.secondaryBright};
  }

  .chevron {
    margin: 0 0 0 10px;
    color: white;
  }
`;

const MenuIcon = styled.div`
  margin-left: 30px;
  color: white;
`;

const HeaderHelpMenu = styled(HelpMenu)``;

const HelpIcon = styled.a`
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin: 5px 0;
  padding: 10px 10px;
  font-size: 18px;
  text-decoration: none;
  color: white;

  &:hover {
    text-decoration: none;
  }

  .bp3-icon {
    color: ${({theme}) => theme};
  }

  @media (min-width: ${media.lg}) {
    padding: 5px 0px;
    background-color: none;

    &:hover {
      text-decoration: none;
      color: ${colors.secondaryBright};
    }

    &.active {
      color: ${colors.secondaryBright};
      font-weight: 600;
    }
  }
`;
